.input {
    outline: none;
    border: none;
    background: inherit;
    border-radius: 1rem;
    border: 0.0625rem solid #F2F2F2; // Converted from 1px
    display: flex;
    padding: 0.875rem 1.5625rem; // Converted from 1.4rem 2.5rem
    align-items: center;
    color: var(--text-1);
    font-size: 0.875rem; // Converted from 1.4rem
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    width: 100%;
    z-index: 10;

    &.default::placeholder {
        color: var(--text-1);
        font-weight: 500;
    }

    &.className {
        border: 0.0625rem solid var(--gray-text); // Converted from 1px
    }

    &.input__date {
        // &::after {
        //     content: '';
        //     position: absolute;
        //     top: 0;
        //     left: 0;
        //     width: 4.375rem; // Converted from 70px
        //     height: 4.375rem; // Converted from 700px
        //     background-image: url('../../../public/Profile/date.svg');
        //     background-size: cover;
        //     cursor: pointer;
        // }
    }

    &:active,
    &:focus {
        border: 0.0625rem solid var(--primary); // Converted from 1px
    }

    &.input__dirty {
        border: 0.0625rem solid var(--primary); // Converted from 1px
    }
}

.input__container {
    font-size: 1rem !important; // Converted from 1.6rem

    .react-datepicker {
        font-size: 0.875rem !important; // Converted from 1.4rem
    }

    position: relative;

    label {
        color: var(--dark-text);
        font-size: 0.875rem; // Converted from 1.4rem
        font-weight: 500;
    }

    display: grid;
    gap: 0.625rem; // Converted from 1rem
}

.wrapperClassName {
    /* This will increase the font size of the entire date picker */
}

.calendarClassName {
    width: 100%;
}

.popperClassName {
    width: max-content;
    height: 9.375rem; // Converted from 600px
}

input.defaultValue {
    &::placeholder {
        font-weight: 500;
        color: var(--text-1);
    }
}
