@use '../../../styles/mixin' as *;


.heading {
    display: grid;
    place-content: center;
    margin-block-end: 1.8125rem;

    h1 {
        color: var(--greyscale-900, var( --gray-scale-text));
        text-align: center;
        font-family: 'Nunito', sans-serif;
        font-size: 2rem;
        font-style: normal;
        font-weight: 700;
        line-height: 120%;
        margin-block-end: 0.8125rem;
        max-width: 30ch;


        @include mobile{
            font-size: 1.5rem;
        }
    }

    p {
        color: var(--greyscale-800, var( --gray-scale-text-light));
        text-align: center;
        font-family: 'Nunito', sans-serif;
        font-size: 1rem;
        font-style: normal;
        font-weight: 500;
        line-height: 140%;
        letter-spacing: 0.02rem;
    }

}
