@use '../../../../styles/mixin' as *;

.form-group {

    input {
        background: var(--gray-light);
        padding: .9rem 1.2rem .75rem 1.25rem;
        @include mobile{
            padding: .5rem;
        }
        border-radius: .625rem;
        border: 1px solid var(--text-color-text-2, var(--gray-text));
        color: var(--black);
        font-size: .875rem;
        max-width: 100%;

        &::placeholder {
            color: var(--black);
            font-size: .875rem;
            font-weight: 500;
        }
    }


}
.mail{
    padding: 1rem;
    padding-block-end: 3.65rem;
}
.bottom{

    p {
        color:  #757575;
        font-size: 1rem;
        font-style: normal;
        font-weight: 500;
        line-height: 140%;
        letter-spacing: 0.02rem;
    }
}

.form-toggle {

    display: flex;
    align-items: center;
    width: min-content;
    gap: .875rem;

    margin-bottom: .9rem;
    cursor: pointer;
    width: 100%;

    label {
        color: #757575;
        font-size: 1rem;
        font-style: normal;
        font-weight: 500;
        line-height: 140%;
        letter-spacing: 0.02rem;


    }

}