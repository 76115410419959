@use '../../styles/mixin' as *;

.item {
    position: relative;
    display: flex;
    padding: 1.625rem 1.5625rem; // Converted from 4.2rem 2.5rem
    align-items: center;
    gap: 2.4375rem; // Converted from 3.9rem
    border-radius: 0.75rem; // Converted from 1.2rem
    background: #FFF;
    cursor: pointer;
    transition: all .3s cubic-bezier(0.25, 0.46, 0.45, 0.94);

    @include mobile {
        gap: 1rem;
        padding: 1rem;
    }



    &.item__selected {
        background-color: darken($color: #fff, $amount: 10%);
    }

    &:hover {
        background-color: darken($color: #fff, $amount: 10%);
    }

    &__title {
        color: var(--greyscale-700, var( --gray-scale-text-light));
        font-family: 'Nunito', sans-serif;
        font-size: 1.375rem; // Converted from 2.2rem
        font-style: normal;
        font-weight: 500;
        line-height: 1.96rem; // Converted from 140%
        letter-spacing: 0.02rem;

        @include mobile {
            font-size: 1rem;
        }

    }
}

.image__container {
    border-radius: 6.25rem; // Converted from 10rem
    background: rgba(0, 188, 211, 0.12);
    display: flex;
    padding: 1rem; // Converted from 1.6rem
    justify-content: center;
    align-items: center;
    gap: 0.625rem; // Converted from 1rem
    min-width: max-content;

    img {
        width: 1.75rem; // Converted from 2.8rem
        height: 1.75rem; // Converted from 2.8rem

        @include mobile {
            width: 1rem; // Converted from 2.8rem
            height: 1rem; // Converted from 2.8rem
        }
    }

}

.insurance__container {
    display: grid;
    gap: 1rem; // Converted from 1.6rem
    width: 28.125rem; // Converted from 45rem
    padding-block-start: 1.875rem; // Converted from 3rem
    padding-block-end: 1.875rem; // Converted from 3rem

    @include mobile {
        width: 100%;
    }
}