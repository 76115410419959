@use '../../styles/mixin' as *;

.header {
    display: flex;
    justify-content: space-between;

    h3 {
        font-size: 1rem; // Converted from 1.8rem
        color: var(--text-dark);
        font-weight: 600;
    }

    a {
        display: flex;
        align-items: center;
        gap: 0.375rem; // Converted from .6rem
        font-weight: 600;
        text-decoration: none;
        font-size: 0.875rem; // Converted from 1.4rem
    }
}

.nameinfo {
    display: flex;
    align-items: center;
    gap: 0.9375rem; // Converted from 1.5rem

    article {
        display: grid;
        gap: 0.3125rem; // Converted from .5rem

        h1 {
            font-size: 0.875rem; // Converted from 1.4rem
            font-weight: var(--semi-bold);
            color: var(--dark-text);
            text-align: left;
        }

        p {
            font-size: 0.75rem; // Converted from 1.2rem
            color: var(--gray-text);
            font-weight: 400;
            text-align: left;
        }
    }
}

.transaction_info {
    p {
        color: var(--text-1);
        font-size: 0.8125rem; // Converted from 1.3rem
        font-weight: 400;
    }

    h1 {
        color: var(--text-color-text-1, var(--dark-text));
        font-size: 0.875rem; // Converted from 1.4rem
        font-weight: 500;
    }
}
