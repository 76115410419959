@use '../../../styles/mixin' as *;

.shop {
    display: grid;
    gap: 60px;
    padding-block-start: 21px;


    &__top {
        display: grid;
        grid-template-columns: 1fr 1fr;
        padding: 22px 0px 32px 20px;
        border-radius: 20px;
        border: 1px solid #EBEEEF;
        background: #FDFDFD;

        @include small-desktop {
            display: flex;
            align-items: flex-start;
        }

        @include mobile {
            display: flex;
            flex-direction: column;
            padding: 20px;

        }



        .top_btn {
            display: flex;

            padding: 9px 57px 15px 58px;
            padding-block-start: 15px;
            padding-block-end: 15px;
            justify-content: center;
            align-items: center;
            gap: 9px;
            border-radius: 10px;
            background: linear-gradient(103deg, #002855 104.22%, var(--black) 104.23%, #181714 104.24%);

            @include small-desktop {
                justify-content: space-between;
            }

            @include mobile {
                padding: 15px;
                width: 100%;
            }

            @include small-mobile {
                padding: 10px;
            }

            &__text {
                color: #FFF;
                font-family: 'Nunito' sans-serif;
                font-size: 14px;
                font-style: normal;
                font-weight: 600;
                line-height: 20px;

                @include small-desktop {
                    font-size: 12px;
                }

                @include small-mobile {
                    font-size: 10px;
                }

            }

        }


        &__left {
            display: grid;
            place-content: center;
            gap: 17px;

            &__image_container {
                width: 337px;
                height: 467px;

                @include small-desktop {
                    width: 90%;
                    height: 100%;
                }

                @include mobile {
                    width: 100%;
                    height: 307px;


                }


                img {
                    width: 100%;
                    height: 100%;
                    object-fit: contain;
                }
            }
        }

        &__right {

            .link__to__live {
                color: var(--primary);
                font-family: "Nunito" sans-serif;
                font-size: 16px;
                font-style: normal;
                font-weight: 700;
                line-height: 24px;
                display: flex;
                align-items: center;
                gap: 12px;
                border-radius: 57px;
                border: 2px solid var(--primary);
                background-color: white;



                @include mobile {

                    font-size: 10px;
                    gap: 5px;

                }

                @include small-mobile {

                    font-size: 10px;
                    padding: 8px;

                }
            }

            .add__to__cart {
                display: flex;
                height: 44px;
                padding: 10px 28px;
                justify-content: center;
                align-items: center;
                border-radius: 57px;
                background: var(--primary);
                color: var(--neutral-color-00, #FDFDFD);
                font-family: "Nunito" sans-serif;
                font-size: 16px;
                font-style: normal;
                font-weight: 700;
                line-height: 24px;

                @include mobile {

                    font-size: 10px;
                    gap: 5px;

                }

                @include small-mobile {

                    font-size: 10px;
                    padding: 8px;

                }
            }

            .action {
                display: flex;
                align-items: center;
                gap: 18px;
                margin-block-end: 30px;

                @include mobile {

                    gap: 1px;

                }

            }

            .quantity {
                cursor: pointer;
                border-radius: 12px;
                background: #F6F6F6;
                width: 100px;
                height: 36px;
                display: flex;
                align-items: center;
                justify-content: space-around;
                margin-block-end: 22px;

                span {
                    color: #3C4242;
                    font-family: 'Causten' sans-serif;
                    font-size: 12px;
                    font-style: normal;
                    font-weight: 500;
                    line-height: normal;
                }
            }


            .heading {
                h3 {
                    color: var(--neutral-color-100, #00171F);
                    font-family: 'Nunito' sans-serif;
                    font-size: 24px;
                    font-style: normal;
                    font-weight: 700;
                    line-height: 36px;
                    max-width: 24ch;

                    @include small-desktop {
                        font-size: 18px;
                        max-width: 18ch;
                    }

                    @include mobile {
                        font-size: 18px;
                        max-width: 18ch;
                    }


                    white-space: nowrap;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    margin-block-end: 6px;
                }

                p {
                    color: var(--primary-color-dark-blue-80, #002A48);
                    font-family: 'Nunito' sans-serif;
                    font-size: 20px;
                    font-style: normal;
                    font-weight: 600;
                    line-height: 32px;
                    margin-block-end: 22px;
                }
            }
        }


    }
}


.steps {

    >h1 {
        font-family: 'Nunito', sans-serif;
        color: #667479;
        font-size: 14px;
        font-style: normal;
        font-weight: 700;
        line-height: 20px;
        padding-block-start: 11px;
        padding-block-end: 10px;
        padding-inline-start: 11px;



    }

    &__first {
        display: flex;
        width: 517px;
        align-items: flex-start;
        flex-direction: column;
        padding-block-start: 11px;
        padding-block-end: 20px;
        padding-inline-start: 11px;
        border-bottom: 1px solid #EBEEEF;
        border-top: 1px solid #EBEEEF;

        @include small-desktop {
            width: 100%;
        }

        @include mobile {
            width: 100%;
        }






        p {
            color: #667479;
            font-family: 'Nunito' sans-serif;
            font-size: 14px;
            font-weight: 400;
            line-height: 20px;
        }
    }

    &__second {
        display: flex;
        gap: 49px;
        color: var(--neutral-color-60, #667479);
        font-family: "SVN-Gilroy" sans-serif;
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: 2rem;
        padding: 8px 0px;
        border-bottom: 1px solid var(--neutral-color-10, #EBEEEF);

        @include small-desktop {
            flex-direction: column;
            gap: 15px;
        }


        p {
            cursor: pointer;
            transition: all .3s cubic-bezier(0.175, 0.885, 0.32, 1.275);

            &:hover {
                color: darken(#EBEEEF, 10%);
            }
        }

    }
}

.bottom {
    &__top {
        display: grid;
        gap: 2px;

        h6 {
            color: var(--black);

            font-family: 'SVN-Gilroy' sans-serif;
            font-size: 16px;
            font-style: normal;
            font-weight: 500;
            line-height: 24px;
        }

        a {
            color: var(--primary-color-dark-blue, #003459);
            font-family: 'Nunito' sans-serif;
            font-size: 24px;
            font-style: normal;
            font-weight: 700;
            line-height: 36px;
            text-transform: capitalize;
        }
    }
}