@use '../../styles/mixin' as *;

.transactions {
    padding-block-start: 3.125rem;
    padding-block-end: 3.125rem;
    padding-inline-start: 2.5rem;
    padding-inline-end: 2.5rem;
    display: grid;
    gap: 1.5625rem;

    @include mobile {
        padding: 1rem;
    }
}
