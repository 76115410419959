

@tailwind base;
@tailwind components;
@tailwind utilities;



html {

  font-family: 'Nunito', sans-serif;


}



@layer components{
.btn-primary {
  @apply flex justify-center  w-full max-w-[100%] md:w-[25rem] rounded-[100px] items-center content-center py-[.8rem] px-[1.25rem] bg-[var(--text-primary)] gap-[1rem] text-white text-[1rem] hover:bg-[#2060E4] transition-all;
}

.redeemed {
  @apply rounded-[0.25rem]  flex items-center py-[0.5rem] px-[0.93rem] text-white bg-[var(--gray-text)] w-[max-content] text-[0.75]
}

.success {
  @apply rounded-[0.4rem]  flex items-center py-[0.8rem] px-[0.93rem] text-[#27AE60] bg-light-bg-green w-[max-content] text-[0.75]
}
.pending {
  @apply rounded-[0.4rem]  flex items-center py-[0.8rem] px-[0.93rem] text-[#EB5757] bg-[#FFEFEF] w-[max-content] text-[0.75]
}


.select-beneficiary{
  @apply cursor-pointer hover:font-bold hover:text-primary;
}

}