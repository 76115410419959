@use '../../../styles/mixin' as *;


.rightmenu {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 2.813rem;

  @include mobile {
    gap: 1rem;
  }
}

.searchbar {
  display: flex;
  align-items: center;


  border-radius:0.25rem;
  padding: 0.5rem;

  svg {
    color: var(--gray-text);
    font-size: 1.5rem;
  }
}

.searchbar input {
  border: none;
  padding: 0.5rem;
  padding-left: 2rem;
  /* Space for the search icon */
  background-color: transparent;
  color: var(--dark-text);
  flex: 1;

}

.notification {
  svg {
    color: var(--gray-text);
  }
}

.profile_dropdown {
  display: flex;
  width: 13.438rem;
  padding: .3rem .9rem .3rem .4rem;
  justify-content: space-between;
  align-items: center;
  border-radius: .62rem;
  background: darken(#FAFAFA , 2%);
  position: relative;
  cursor: pointer;
  gap: .654rem;
  min-width: max-content;


  @include mobile {
    gap: .654rem;
    justify-content: flex-start;
    gap: .654rem;
    width: max-content;
  }

  >section {
    display: flex;
    align-items: center;
    gap: .268rem;

    h3 {
      @include mobile {
        display: none;
      }


      font-weight: 600;
      font-size: .875rem;
      color: var(--dark-text);
      line-height: .875rem;
      max-width: 18ch;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;



    }
  }
}

.show{
  visibility: visible;
}
