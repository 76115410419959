@use '../../../styles/mixin' as *;

.chartheader {
    padding-block-start: 0.025rem;
    padding-block-end: 0.025rem;
    display: flex;
    align-items: center;
    justify-content: space-between;

    h1 {
        font-size: 1.125rem; /* 1.125rem */
        color: var(--dark-text);
        font-weight: 600;
    }

    &__right,
    &__right__left,
    .expenses,
    .income,
    &__right__right {
        display: flex;
        align-items: center;
        justify-content: space-between;
    }

    .expenses,
    .income,
    &__right__right {
        justify-content: flex-start;
    }

    .expenses {
        display: flex;
        gap: 0.5625rem; /* 0.5625rem */

        svg {
            color: var(--primary);
            font-size: 1.5rem; /* 1.5rem */
        }
    }

    .income {
        gap: 0.5625rem; /* 0.5625rem */

        svg {
            color: var(--secondary);
            font-size: 1.5rem; /* 1.5rem */
        }
    }
}

.chartheader__right__right {
    background-color: var(--gray-bg);
    gap: 0.625rem; /* 0.625rem */
    padding: 0.0375rem; /* 0.0375rem */
    border-radius: 0.03125rem; /* 0.03125rem */
    padding: 0.0375rem 0.05rem 0.0375rem 0.0625rem; /* 0.0375rem 0.05rem 0.0375rem 0.0625rem */
    position: relative;
    h3 {
        font-size: 0.75rem; /* 0.75rem */
    }
    // svg {
    //     font-size: 1.125rem; /* 1.125rem */
    //     path {
    //         fill: var(--black);
    //         font-weight: 900;
    //     }
    // }

    h3,
    svg {
        color: var(--dark-text);
    }
}

.chartheader__right {
    width: 3.75rem; /* 3.75rem */
    min-width: max-content;
}

.chartheader__right__left {
    gap: 1.875rem; /* 1.875rem */

    @include mobile {
        display: none;
    }
}
