@use '../../styles/mixin' as *;

.categories {
    display: grid;
    gap: 1.375rem; /* Adjusted from 2.2rem */
    h2 {
        font-size: 1.25rem; /* Adjusted from 2rem */
        font-weight: 500;
        color: var(--dark-text);
    }
    &__container {
        display: grid;
        grid-template-columns: repeat(5, 1fr);
        gap: 2.5rem; /* Adjusted from 4rem */

        @include mobile {
            grid-template-columns: repeat(2, 1fr);
            gap: 0.9375rem; /* Adjusted from 1.5rem */
        }

        @include tablet {
            grid-template-columns: repeat(3, 1fr);
            gap: 1.25rem; /* Adjusted from 2rem */
        }

        @include small-desktop {
            grid-template-columns: repeat(3, 1fr);
        }
    }
}
