@use '../../../styles/mixin' as *;

.banner {
    display: flex;
    flex-direction: column;
    justify-content: center;
    background: #1959C9;
    width: 100%;
    height: 19.75rem;
    border-radius: 1rem;
    position: relative;

    @include mobile{
        height: 12.5rem;
    }

    .current {
        position: absolute;
        left: .625rem;
        >div {
            display: flex;
            gap :.625rem;
            height: 0.5rem;
        }
    }

    .image__container {
        width: 100%;
        height: 19.75rem;
        position: absolute;
        top: 0;

        img {
            width: 100%;
            height: 100%;
            object-fit: contain;
        }

        @include mobile{
            width: 100%;
            height: 100%;

        }

    }



    // // 1240 max
    // @media screen and (max-width: 1240px) {
    //     height: 26.6rem;
    // }

    // @media (max-width: 1024px) {
    //     height: 25.6rem;
    // }

    // @media (max-width: 768px) {
    //     height: 21.6rem;
    // }

    // @media (max-width: 480px) {
    //     height: 17.6rem;
    // }


    .next__and__prev {
        display: flex;
        justify-content: space-between;
        align-items: center;

        .left,
        .right {
            position: relative;
        }

        .next__btn {
            position: absolute;
            right: -2rem;
            bottom: -2rem;
        }

        .prev__btn {
            position: absolute;
            right: -2rem;
            bottom: -2rem;
        }

        .next__btn,
        .prev__btn {
            width: 2.688rem;
            height: 2.688rem;
            border-radius: 50%;
            background: #fff;
            display: flex;
            justify-content: center;
            align-items: center;
            cursor: pointer;
            z-index: 10;

            >div {
                display: flex;
                justify-content: center;
                align-items: center;
                width: 1.5rem;
                height: 1.5rem;
                border-radius: 50%;
                background-color: #F3F9FB;

                img {
                    width: .7rem;
                    height: .7rem;
                }
            }


        }
    }



}