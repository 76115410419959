@use '../../styles/mixin' as *;

.cashInfo__item {
    display: flex;
    justify-content: flex-start;
    align-items: center;

    @include mobile {
        display: flex;
        place-items: center;
        justify-items: space-between;
    }

    @include large-desktop {
        padding-block-start: 1.5rem;
        padding-block-end: 1.5rem;
        padding-inline-start: 2rem;
        padding-inline-end: 2rem;
    }

    padding-block-start: 1.25rem;
    padding-block-end: 1.25rem;
    padding-inline-start: 1.25rem;
    padding-inline-end: 1.25rem;

    background-color: var(--gray-bg);
    border-radius: 0.625rem;
    gap: 1.25rem;

    &__icon {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 2.625rem;
        height: 2.625rem;
        min-width: 2.625rem;
        min-height: 2.625rem;
        background-color: var(--gray-light-bg);
        border-radius: 50%;
    }

    &__info {
        display: grid;
        gap: 0.625rem;

        h3 {
            color: var(--gray-text);

            @include large-desktop {
                font-size: 0.875rem;
            }

            font-size: 0.75rem;
            font-weight: 400;
        }

        &__value {
            display: flex;
            gap: 0.125rem;
            align-items: center;

            p {
                font-size: 1rem;
                font-weight: 700;
                color: var(--dark-text);

                @include small-desktop {
                    // No changes
                }

                @media screen and (min-width: 1399px) {
                    font-size: 1.25rem;
                    // No changes
                }
            }
        }
    }
}
