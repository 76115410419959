@use '../../../styles/mixin' as *;

@function h($value) {
  @return #{$value / (800 / 900)}vh;
}

.form {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1.25rem; /* Adjusted from 2.0rem to 1.25rem */
  padding: 0.9375rem 0.625rem 1.0rem 0.625rem; /* Adjusted from 1.5rem 1rem 1.6rem 1.0rem */
  width: 36.375rem; /* Adjusted from 58.2rem */
  border-radius: 0.75rem; /* Adjusted from 12px */
  justify-content: center;

  @include mobile {
    width: 90vw;
  }
}

/* ReadyToSend.module.scss */
.ready_to_send {
  width: 100%;
  background-color: var(--gray-light-bg);
  padding: 1.25rem; /* Adjusted from 2.0rem */
  border-radius: 0.45rem; /* Adjusted from .8rem */
  box-shadow: 0px 0.25rem 0.5rem rgba(0, 0, 0, 0.1); /* Adjusted from .4rem .8rem */
  color: var(--dark-text);

  h3 {
    color: var(--primary);
    font-weight: var(--bold);
    margin-bottom: 1.125rem; /* Adjusted from 1.6rem */
  }

  div {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 1rem; /* Adjusted from 1.6rem */
  }

  article {
    background-color: var(--white);
    border-radius: 0.45rem; /* Adjusted from .8rem */
    padding: 1rem; /* Adjusted from 1.6rem */
    box-shadow: 0px 0.125rem 0.25rem rgba(0, 0, 0, 0.05); /* Adjusted from .2rem .4rem */

    h4 {
      color: var(--dark-text);
      font-weight: var(--semi-bold);
      margin-bottom: 0.5rem; /* Adjusted from .8rem */
    }

    p {
      color: var(--gray-scale-text);
    }
  }
}
