@use '../../../styles/mixin' as *;

.link {
  // padding-inline-start: 1.6rem;
  // padding-inline-end: 1.6rem;
  // padding-block-start: 1.4rem;
  // padding-block-end: 1.8rem;
  display: grid;
  padding: 0.875rem 5.0625rem 0.875rem 0.9375rem; /* Converted from 1.4rem 8.1rem 1.4rem 1.5rem */
  align-items: center;
  gap: 0.75rem; /* Converted from 1.2rem */
  grid-template-columns: max-content 1fr;

  :nth-child(10){
    p{
      padding-inline-start: 0.625rem; /* Converted from 1rem */
    }
  }

  // paragraph
  p{
    padding-block-start: 0.125rem; /* Converted from 2px */
  }

  font-size: 0.875rem; /* Converted from 1.4rem */
  border-radius: 0.5rem; /* Converted from .8rem */
  color: var(--gray-text);
  text-decoration: none;
  transition: all 0.3s ease-in-out;
  font-weight: 500;
  margin-block-end: 0.1875rem; /* Converted from .3rem */

  // desktop screen
  @include large-desktop{
    padding: 0.875rem 4.0625rem 0.875rem 0.9375rem;
  }
  padding: 0.875rem 2.5625rem 0.875rem 0.9375rem;

  &:hover {
    color: var(--white);
    background-color: var(--primary);
    font-weight: 500;
    svg{
      path{
        fill: var(--white);
      }
    }
  }

  svg{
    path{
      color: var(--white);
    }
  }

  &.active {
    background-color: var(--primary);
    color: var(--white);
    font-weight: 700;
    svg{
      path{
        fill: #fff;
      }
    }
  }
}
