@use '../../../styles/mixin' as *;

.fundviaTable {

    &__container {
        border: 0.0625rem solid var(--French-Grey, #D1D1D8); // Converted from 1px
        border-block-end: none;
    }

    &__item {
        padding: 0.9375rem 1.5625rem; // Converted from 1.5rem 2.5rem
        display: flex;
        gap: 0.75rem; // Converted from 1.2rem
        align-items: center;
        border-bottom: 0.0625rem solid #D1D1D8; // Converted from 1px

        @include small-mobile{
            gap: 0.375rem; // Converted from .6rem
            padding: 0.625rem 0.9375rem; // Converted from 1rem 1.5rem
            flex-wrap: wrap;
        }

        @include small-desktop{
            padding: 0.625rem 0.9375rem; // Converted from 1rem 1.5rem
            flex-wrap: wrap;

        }
        @include mobile{
            gap: 0.3125rem 0.625rem; // Converted from .5rem 1rem
            padding: 0.625rem 1.25rem; // Converted from 1rem 2rem
            flex-wrap: wrap;
        }

    }

    >h1 {
        color: var(--greyscale-900, var( --gray-scale-text));
        font-family: 'Nunito', sans-serif;
        font-size: 1.125rem; // Converted from 1.8rem
        font-style: normal;
        font-weight: 700;
        line-height: 1.35rem; // Converted from 120%
        margin-block-end: 0.6875rem; // Converted from 1.1rem

        @include mobile{
            font-size: 0.9375rem; // Converted from 1.5rem
        }

    }
}

.fundviaTable__item {
    &__title {
        color: var(--greyscale-900, var( --gray-scale-text));
        font-family: 'Nunito', sans-serif;
        font-size: 1.125rem; // Converted from 1.8rem
        font-style: normal;
        font-weight: 700;
        line-height: 1.35rem; // Converted from 120%
        @include mobile{
            font-size: 0.9375rem; // Converted from 1.5rem
        }
    }

    &__desc {
        color: #484848;
        font-family: 'Nunito', sans-serif;
        font-size: 1rem; // Converted from 1.4rem
        font-style: normal;
        font-weight: 500;
        line-height: 1.4rem; // Converted from 140%
        letter-spacing: 0.02rem;
        @include small-mobile{
            font-size: 0.625rem; // Converted from 1rem
        }
    }
}
