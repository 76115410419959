@use '../../../styles/mixin' as *;

.form {
    display: grid;
    gap: 19px;
    width: 582px;
    padding: 30px 89px 51px 89px;
    border-radius: 12px;
    border: 1px solid var(--Light-Gray, #B2B3B3);
    background: var(--gray-gray-1, var(--gray-light));
    @include mobile{
        width: 100%;
        padding: 30px 20px 51px 20px;
    }

    >p , label {
        color: var(--greyscale-700, #616161);
        font-family: 'Nunito', sans-serif;
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: 140%;



    }


}


.confirm {
    padding: 24px 54px 44px 54px;
    border-radius: 12px;
    border: 1px solid #B2B3B3;
    background: var(--gray-light);
    width: 582px;
    display: flex;
    flex-direction: column;

    margin: 0 auto;
    @include mobile{
        width: 100%;
        padding: 20px;
    }

    &__item {
        display: flex;
        justify-content: space-between;
        align-items: center;
        gap: 30px;


        p {
            color: var(--greyscale-700, #616161);
            font-family: 'Nunito', sans-serif;
            font-size: 16px;
            font-style: normal;
            font-weight: 500;
            line-height: 140%;


            &.confirm__item__last {
                font-weight: 900;
                color: var(--black);
            }
        }

        h3 {
            color: var(--greyscale-900, var( --gray-scale-text));
            text-align: right;
            font-family: 'Nunito', sans-serif;
            font-size: 18px;
            font-style: normal;
            font-weight: 700;
            line-height: 140%;

        }
    }

    &__container {
        border-block-start: 1px solid #EEE;
        padding-block-start: 26px;
        display: grid;
        gap: 25px;

    }

}

// Pin
.pin__form {
    display: grid;
    place-content: center;
    place-items: center;

}

.confirm__save {
    display: grid;
    place-content: center;
    grid-template-columns: 1fr 1fr;
    place-items: center;
    padding-block-start: 23px;
    cursor: pointer;

    h3 {
        color: var(--greyscale-700, #616161);
        font-family: 'Nunito', sans-serif;
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: 140%;

    }
}