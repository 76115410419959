.title {
    color: var(--greyscale-700, #616161);
    font-family: 'Nunito', sans-serif;
    font-size: 0.875rem; /* Converted from 1.4rem */
    font-style: normal;
    font-weight: 500;
    line-height: 1.4; /* Converted from 140% */
    letter-spacing: 0.00125rem; /* Converted from 0.02rem */
    position: absolute;
    min-width: max-content;
    left: -0.25rem; /* Converted from -4rem */
    top: 1.6875rem; /* Converted from 2.7rem */
    &.titleLastLevel {
        left: -0.125rem; /* Converted from -2rem */
    }
}
