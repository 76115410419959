@use '../../styles/mixin' as *;

.category {

    display: flex;
    flex-direction: column;
    gap: 0.75rem;
    text-decoration: none;
    background-color: #fff;
    border-radius: 0.75rem;
    box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.08);
    justify-content: center;
    align-items: center;
    padding: 1.4375rem 1.9375rem;
    &:hover{
        background-color: rgb(251, 251, 251);
        transition: all .5s cubic-bezier(0.19, 1, 0.22, 1);
        transform: scale(1.05);


    }


    @include large-desktop {
        width: 10rem;
        height: 9.75rem;
        padding: 0;
    }




    &__image {
        width: 3.75rem;
        height: 3.75rem;
        padding: 0.875rem;
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    h3 {
        color: var(--greyscale-800, var( --gray-scale-text-light));
        text-align: center;
        font-family: 'Nunito', sans-serif;
        font-size: 1rem;
        font-style: normal;
        font-weight: 700;
        line-height: 1.4; /* 1.4rem */
        letter-spacing: 0.02rem;
    }

}
