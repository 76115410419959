.backbutton_container {
    display: flex;
    align-items: center;
    gap: 0.75rem; /* Converted from 1.2rem */
    color: var( --gray-scale-text);
    width: max-content;
    text-decoration: none;

    span {
        color: var(--greyscale-900, var( --gray-scale-text));
        font-family: 'Nunito', sans-serif;
        font-size: 1.125rem; /* Converted from 1.8rem */
        font-style: normal;
        font-weight: 700;
        line-height: 120%;
        letter-spacing: 0.02em;
        text-decoration: none;
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
        width: 20; /* Not converted because it seems to be a fixed value */
        padding-right: 0.625rem; /* Converted from 1rem */
    }
}
