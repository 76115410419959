@use '../../styles/mixin' as *;

.home {
    padding-block-start: 1.875rem;
    display: grid;
    gap: 1.875rem;

    &__bottom {
        @include mobile {
            grid-template-columns: 1fr;
        }
        @include tablet {
            grid-template-columns: 1fr;
        }
        @include small-desktop {
            grid-template-columns: 1fr 1fr;
        }

        display: grid;
        grid-template-columns: 65% 1fr;
        gap: 1.25rem;

        &__left,
        &__right {
            display: grid;
            gap: 1.875rem;
            grid-template-rows: max-content max-content;

        }

        &__right {
            gap: 2.6875rem;
        }
    }
}

.home__bottom__left__bottom,
.home__bottom__right__bottom {
    border-radius: 0.625rem;
    display: grid;
    gap: 1.375rem;
}

.home__bottom__right__top {
    @include mobile {
        display: none;
    }
}

.home__mobile__card {
    display: none;
    @include mobile {
        display: block;
    }
}
