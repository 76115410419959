@use '../../../styles/mixin' as *;

.header {
    display: grid;
    background: var(--primary);
    grid-template-columns: 2fr repeat(4, 1fr); /* Adjusted values */
    // place-items: center;
    padding-block-start: 1.625rem; /* 2.6rem */
    padding-block-end: 1.625rem; /* 2.6rem */
    gap: 1rem;

    h1 {
        color: #FFF;
        font-family: "Causten" sans-serif;

        font-style: normal;
        font-weight: 600;
        line-height: normal;
        text-transform: uppercase;
        text-align: center;


    }
}
