@use '../../../styles/mixin' as *;
@function h($value) {
  @return #{$value / (800 / 900)}vh;
}
.form {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap:1.25rem;
    padding: .9rem .65rem 1rem .65rem;
    width: 36.375;
    border-radius: 12px;






   justify-content: center;

   @include mobile{
    width: 90vw;

   }



}

/* ReadyToSend.module.scss */
.ready_to_send {
    width: 100%;
    background-color: var(--gray-light-bg);
    padding: 1.25rem;
    border-radius: .5rem;
    box-shadow: 0px .25rem .5rem rgba(0, 0, 0, 0.1);
    color: var(--dark-text);

    h3 {
      color: var(--primary);
      font-weight: var(--bold);
      margin-bottom: 1rem;
    }

    div {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      gap: 1rem;
    }

    article {
      background-color: var(--white);
      border-radius: .5rem;
      padding: 1rem;
      box-shadow: 0px .025rem .25rem rgba(0, 0, 0, 0.05);

      h4 {
        color: var(--dark-text);
        font-weight: var(--semi-bold);
        margin-bottom: .5rem;
      }

      p {
        color: var(--gray-scale-text);
      }
    }
  }
