@use '../../../styles/mixin' as * ;

.currencybutton {
    display: flex;
    align-items: center;
    gap: 0.5rem; /* Converted from .8rem */
    background: var(--primary-light);
    padding: 0.5rem 0.75rem 0.5rem 0.75rem; /* Converted from .8rem 1.2rem .8rem 1.2rem */
    border-radius: 62.5rem; /* Converted from 1000px */

    &__left {
        background: linear-gradient(to right, var(--primary) 0%, #6F9EFF 100%);
        width: 1.5rem; /* Converted from 2.4rem */
        height: 1.5rem; /* Converted from 2.4rem */
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
        text-align: center;
        color: var(--white);
    }

    &__right {
        font-size: 1.2rem; /* Converted from 1.92rem */
        color: var(--dark-text);
        font-weight: 700;
    }
}

.currencybutton__right {
    @include small-mobile {
        display: none;
    }
}
