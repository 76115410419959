@use '../../../styles/mixin' as *;

.cardlist {
    display: grid;
    padding-block-start: 40px;
    justify-content: space-between;
    gap: 40px;
    row-gap: 20px;
    place-content: center;


    @include large-desktop {
        grid-template-columns: repeat(3, minmax(262px, 33%));
        justify-content: space-between;
        column-gap: 70px;
        row-gap: 70px;

    }

    @include desktop {
        grid-template-columns: repeat(3, minmax(202px, 33%));
        justify-content: space-between;

        column-gap: 20px;
        row-gap: 50px;



    }

    @include small-desktop {
        grid-template-columns: repeat(3, minmax(202px, 33%));
        justify-content: space-between;

        column-gap: 20px;
        row-gap: 20px;
    }



    @include tablet {
        grid-template-columns: repeat(2, 1fr);

    }









}

.scrollable {
    &::-webkit-scrollbar {
        width: 10px;
    }

    &::-webkit-scrollbar-track {
        background-color: #f0f0f0;
        border-radius: 10px;
    }

    &::-webkit-scrollbar-thumb {
        background-color: #fafafa;
        border-radius: 10px;
        box-shadow: inset 0 0 5px #7a7a7a;
        min-height: 30px;
    }
}