@use '../../styles/mixin' as * ;

.cashinfo_container{
    @include mobile{
        grid-template-columns: 1fr;
        overflow: scroll;
        gap: 1rem;
    }
    @include tablet{

        grid-template-columns: repeat(2, 1fr);
    }
    @include small-desktop{

        grid-template-columns: repeat(2, 1fr);
    }
    display: grid;
    grid-template-columns: repeat(4, minmax(0, 1fr));
    gap : 1.9rem;
}