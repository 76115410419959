@use '../../styles/mixin' as *;

.confirm__fund__title {
    color: var(--greyscale-900, var( --gray-scale-text));
    text-align: center;
    font-family: 'Nunito', sans-serif;
    font-size: 2rem; /* 2rem */
    font-style: normal;
    font-weight: 700;
    line-height: 110%; /* 2.75rem */
    @include large-desktop {
        margin-block-start: 4.8125rem; /* 4.8125rem */
        margin-block-end: 2rem; /* 2rem */
    }
    margin-block-start: 3.125rem; /* 3.125rem */
    margin-block-end: 1.375rem; /* 1.375rem */

    @include mobile {
        font-size: 1.25rem; /* 1.25rem */
    }
}

.confirm__fund__form__title {
    color: var(--greyscale-900, var( --gray-scale-text));
    font-family: 'Nunito', sans-serif;
    font-size: 1.125rem; /* 1.125rem */
    font-style: normal;
    font-weight: 500;
    line-height: 1.75rem; /* 1.75rem */
    letter-spacing: 0.02rem;

    @include mobile {
        font-size: 1rem; /* 1rem */
    }
}

.confirm__fund {
    display: flex;
    flex-direction: column;
    align-items: center;
}
