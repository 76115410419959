@use '../../styles/mixin' as * ;

.token__info {
    width: 580px;
    @include mobile{
        width: 100%;
        max-width: 98vw;
    }
    display: grid;
    border-radius: 12px;
    border: 1px solid #B2B3B3;
    background: var(--gray-light);
}

.token__image__container {
    display: grid;
    place-content: center;
    padding: 1.5rem;
    @include mobile{
        padding: 1rem;
    }
    border-block-end: 1px solid #EEE;

    >div {
        border-radius: .675rem;
        background: rgba(255, 211, 0, 0.12);
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 10px;
        width: 80px;
        height: 80px
    }
}

.token__digits {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 0.5rem;
    padding: 30px;
    @include mobile{
        padding:1rem;
    }

    span {
        color: var(--primary);
        font-size: 1.5rem;
        @include mobile{
            font-size: 1rem;
        }
        font-style: normal;
        font-weight: 700;
        line-height: 140%;
        letter-spacing: 0.02rem;
    }
}

.list {
    padding: 0 54px;
    padding-block-end: 3.9rem;
    @include mobile{
        padding: 0 1rem;
        padding-block-end: 1rem;
    }
    display: grid;
    place-content: center;
    gap: 20px;

    .item {
        display: grid;
        grid-template-columns: 1fr 1fr;
        gap: 1rem;

        &__left {
            color: #616161;
            font-size: .897rem;
            font-style: normal;
            font-weight: 500;
            line-height: 140%;
            letter-spacing: 0.02rem;

            &.total {
                font-weight: 700;
                color: var(--black);
            }
        }

        &__right {
            color: var(--greyscale-900, var( --gray-scale-text));
            text-align: right;
            font-size: 1rem;
            font-weight: 600;
            line-height: 140%;
            letter-spacing: 0.02rem;
        }
    }
}

.btn {
    &__print {
        border-radius: 100px;
        background: var(--primary-light);
        color: var(--primary);
        text-align: center;
        text-shadow: 4px 8px 24px rgba(36, 107, 253, 0.25);
        font-size: 1rem;
        font-weight: 700;
        line-height: 140%;
        letter-spacing: 0.02rem;
    }

    &__dashboard {
        color: var(--white);
        text-align: center;
        text-shadow: 4px 8px 24px rgba(36, 107, 253, 0.25);
        font-size: 1rem;
        font-weight: 700;
        line-height: 140%;
        letter-spacing: 0.02rem;
        border-radius: 100px;
        background: var(--primary);
        box-shadow: 4px 8px 24px 0px rgba(36, 107, 253, 0.25);
    }

    &__dashboard,
    &__print {
        display: flex;
        padding: 18px 1rem;
        @include mobile{
            padding: 1rem;
        }
        justify-content: center;
        align-items: center;
    }
}
