@use '../../styles/mixin' as *;

.servicescontainer{
    display: grid;
    gap : 2rem;
    justify-content: center;
    padding-inline-start: 4.375rem;
    padding-block-start: 3.75rem;
    padding-inline-end: 4.375rem;
    padding-block-end: 1.875rem;

    @include mobile{
        padding-inline-start: 1rem;
        padding-inline-end: 1rem;
       }

    @include tablet{
        padding-inline-start: 2.8125rem;
        padding-inline-end: 2.8125rem;
    }
}

