
@use '../../../styles/mixin' as *;

.dashboard{
    padding-inline-end: 3.8125rem;
    padding-inline-start: 2.125rem;

    @include mobile {
        padding-inline-end: 1.25rem;
        padding-inline-start: 1.25rem;
    }
}
