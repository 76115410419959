.container {

    // padding : 1.9rem 5.0625rem 0.875rem 0.9375rem;
    // background-color: var(--gray-light);
    top: 0;
    left: 0;
    z-index: 20;
    position: sticky;
    height: 100vh;
    transition: all .5s ease;
    overflow-y: hidden;
    max-height: 100vh;
    


    &:hover {
        overflow-y: auto;

    }








}