@use '../../../styles/mixin' as *;

.cashinfo {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 1.5rem; /* Converted from 2.4rem */
  border-radius: 1.5rem; /* Converted from 2.4rem */
  border: 0.1rem solid var(--primary);
  width: auto;

  @include mobile {
    padding: 1rem; /* Converted from 1.6rem */
  }

  &__input {
    color: var(--greyscale-900, var( --gray-scale-text));
    font-family: 'Nunito', sans-serif;
    font-size: 1.875rem; /* Converted from 3rem */
    font-style: normal;
    font-weight: 700;
    line-height: 110%;
    outline: none;
    border: none;
    width: 100%;
    background: inherit;
    text-align: right;

    &::placeholder {
      font-weight: 700;
      font-size: 1.875rem; /* Converted from 3rem */
      color: var(--greyscale-900, var( --gray-scale-text));

      @include mobile {
        font-size: 1.25rem; /* Converted from 2rem */
      }
    }

    @include mobile {
      padding: 1rem; /* Converted from 1.6rem */
      width: 100%;
      font-size: 1.25rem; /* Converted from 2rem */
    }
  }
}
