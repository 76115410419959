@use '../../styles/mixin' as *;

.topmenu {

    @include mobile {
        padding-inline-start: .5rem;
        padding-inline-end: .5rem;
        width: 100%;
    }

    @include tablet {
        padding-inline-start: 2rem;
        /* Converted from 3.2rem */
        padding-inline-end: 2rem;
        /* Converted from 3.2rem */
    }

    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 1rem;

    @include mobile {
        gap: .5rem
    }

    &__mobile {
        display: none;
        position: relative;

        @include mobile {
            display: block;
        }

        @include tablet {
            display: block;
        }

        .sidemenu {
            position: absolute;
            width: 100vw;
            top: 3rem;
            /* Converted from 4.8rem */
            z-index: 9000;
            background: rgba(0, 0, 0, 0.377);

            @include tablet {
                left: -4.375rem;
            }

            @include mobile {
                left: -1.875rem;
            }

            >* {
                min-width: max-content;
                height: max-content;
                padding: 10%;
                background-color: var(--gray-bg);
                overflow-y: auto;
                min-height: max-content;

                @include mobile {
                    width: 70%;
                }

                @include tablet {
                    width: 50%;
                }
            }
        }
    }
}

.transaction__search {
    display: flex;
    width: max-content;
    display: inline-flex;
    padding-inline-start: .87rem;
    /* Converted from 1.5rem */
    padding-inline-end: .87rem;
    /* Converted from 1.5rem */
    padding-block-end: .87rem;
    /* Converted from 1.5rem */
    padding-block-start: .87rem;
    /* Converted from 1.5rem */
    align-items: center;
    gap: 1rem;
    /* Converted from 1.5rem */
    border-radius: 1rem;
    /* Converted from 1.5rem */
    border: 1px solid #F5F5F5;
    background: var(--gray-bg);
    min-width: 21.0625rem;

    @include mobile {
        min-width: 80vw;
    }

    margin-block-start: 1.25rem;
    /* Converted from 2rem */

    input {
        font-size: .875rem;
        font-weight: 400;
        outline: none;
        border: none;
        background: inherit;
        color: #363A3F;
        width: 100%;

        &:active,
        &:focus,
        &:hover {
            outline: none;
            border: none;
            color: var(--gray-text);
        }
    }
}