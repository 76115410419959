@use '../../../styles/mixin' as *;

.leftmenu {
    h1 {

        color: var(--dark-text);
        font-size: 25px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
        width: 20ch;
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;

        @include mobile {
        width: 15ch;
        font-size: 1.1rem;
        }

    }

    // @include mobile {
    //     display: none;
    // }
}

.search {
    display: flex;
    align-items: center;
    gap: 15px;

    // padding: 12px 43px 12px 15px;
    @include mobile{
        padding: .5rem;
        gap: .5rem;
    }
    border-radius: 15px;
    // border: 1px solid  var(--gray-text);
    background:  var(--gray-bg) ;

    @include large-desktop{
        width: 761px;
    }
    @include desktop {
        width: 600px;
    }
    @include mobile{
        width:  100%;
    }




    input {
        color: #666;
        font-family: 'Nunito' sans-serif;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 18px;
        outline: none;
        border: none;
        background:  var(--gray-bg) ;
        width: 100%;
    }

}