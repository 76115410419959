@use '../../../styles/mixin' as *;

.item {
    display: grid;
    grid-template-columns: 2fr repeat(4, 1fr);
    /* Adjusted values */
    place-items: center;
    padding-block-end: 2rem;
    /* 3.2rem */
    border-block-end: 1px solid #BEBCBD;
    gap: 1rem;

    // @include mobile{
    //     grid-template-columns: 1fr;
    // }

    .price {
        color: #3C4242;
        font-family: 'Nunito' sans-serif;
        font-size: 1.125rem;
        /* 1.8rem */
        font-style: normal;
        font-weight: 500;
        line-height: normal;
    }

    .subtotal {
        color: #3C4242;
        font-family: 'Nunito' sans-serif;
        font-size: 1.125rem;
        /* 1.8rem */
        font-style: normal;
        font-weight: 600;
        line-height: normal;
    }

    .product__info {
        display: grid;
        gap: 1.6rem;
        /* Adjusted gap */
        grid-template-columns: 1fr 1fr;

        @include mobile {
            gap: .5rem;

        }

        p {
            color: #3C4242;
            font-family: 'Nunito' sans-serif;
            font-size: 1.125rem;
            /* 1.8rem */
            font-style: normal;
            font-weight: 600;
            line-height: 1.5;
            /* 2.4rem */
            letter-spacing: 0.036rem;
            width: 22ch;

            @include desktop {
                font-size: 0.9375rem;
                /* 1.5rem */
            }

            @include small-desktop {
                font-size: 0.75rem;
                /* 1.2rem */
            }

            @include mobile {
                font-size: 0.65rem;
            }
        }

        &__image__container {
            width: 5.25rem;
            /* 10.5rem */
            height: 6rem;

            /* 12rem */
            @include mobile {
                width: 3rem;
                height: 3rem
            }

            img {
                width: 100%;
                height: 100%;
                object-fit: contain;
                border-radius: .7rem;
            }
        }
    }
}

.quantity {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    gap: 1rem;
    align-items: center;
    justify-items: center;
    border-radius: .75rem;
    background: #F6F6F6;
    padding: 0.6875rem 1.5625rem;
    /* 1.1rem 2.5rem */
    cursor: pointer;

    span {
        color: #3C4242;
        font-family: "Causten" sans-serif;
        font-size: 0.75rem;
        /* 1.2rem */
        font-style: normal;
        font-weight: 500;
        line-height: normal;
    }
}