@use 'sass:math';
@use 'sass:color';
@use 'sass:map';
@use 'sass:meta';
@use 'sass:selector';
@use 'sass:string';


@function pRem($size) {
    @return math.div($size, 16px) * 1rem;
}

@function pEm($size) {
    @return math.div($size, 16px) * 1em;
}

@mixin font($size, $weight: normal, $line-height: 1.5) {
    font-size: pRem($size);
    font-weight: $weight;
    line-height: $line-height;
}

@mixin font-size($size) {
    font-size: pRem($size);
}

@mixin font-weight($weight) {
    font-weight: $weight;
}


@mixin flex-row-center {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

@mixin flex-column-center {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

@mixin flex-row-space-between {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

@mixin flex-row-space-around {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
}

@mixin flex-row-space-evenly {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: center;
}

@mixin flex-column-space-between {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
}
@mixin flex-row-flex-start {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
}

@mixin flex-column-flex-start {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
}
@mixin flex-column-space-around {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
}


@mixin flex-column-space-evenly {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
}


@mixin flex-row($justify-content: space-between, $align-items: center) {
    display: flex;
    flex-direction: row;
    justify-content: $justify-content;
    align-items: $align-items;
}

@mixin flex-column($justify-content: space-between, $align-items: center) {
    display: flex;
    flex-direction: column;
    justify-content: $justify-content;
    align-items: $align-items;
}

@mixin flex($direction: row, $justify-content: space-between, $align-items: center) {
    display: flex;
    flex-direction: $direction;
    justify-content: $justify-content;
    align-items: $align-items;
}

@mixin flex-wrap($wrap: wrap, $justify-content: space-between, $align-items: center) {
    display: flex;
    flex-wrap: $wrap;
    justify-content: $justify-content;
    align-items: $align-items;
}

@mixin flex-wrap-reverse($wrap: wrap-reverse, $justify-content: space-between, $align-items: center) {
    display: flex;
    flex-wrap: $wrap;
    justify-content: $justify-content;
    align-items: $align-items;
}

@mixin flex-wrap-column($wrap: wrap, $justify-content: space-between, $align-items: center) {
    display: flex;
    flex-wrap: $wrap;
    flex-direction: column;
    justify-content: $justify-content;
    align-items: $align-items;
}

@mixin flex-wrap-column-reverse($wrap: wrap-reverse, $justify-content: space-between, $align-items: center) {
    display: flex;
    flex-wrap: $wrap;
    flex-direction: column-reverse;
    justify-content: $justify-content;
    align-items: $align-items;
}

@mixin flex-wrap-row($wrap: wrap, $justify-content: space-between, $align-items: center) {
    display: flex;
    flex-wrap: $wrap;
    flex-direction: row;
    justify-content: $justify-content;
    align-items: $align-items;
}

@mixin grid($columns: 1fr, $rows: 1fr, $gap: 0, $justify-content: center, $align-items: center) {
    display: grid;
    grid-template-columns: $columns;
    grid-template-rows: $rows;
    grid-gap: $gap;
    justify-content: $justify-content;
    align-items: $align-items;
}

@mixin grid-column($columns: 1fr, $gap: 0, $justify-content: center, $align-items: center) {
    display: grid;
    grid-template-columns: $columns;
    grid-gap: $gap;
    justify-content: $justify-content;
    align-items: $align-items;
}

@mixin grid-row($rows: 1fr, $gap: 0, $justify-content: center, $align-items: center) {
    display: grid;
    grid-template-rows: $rows;
    grid-gap: $gap;
    justify-content: $justify-content;
    align-items: $align-items;
}

@mixin grid-auto-columns($columns: 1fr, $gap: 0, $justify-content: center, $align-items: center) {
    display: grid;
    grid-auto-columns: $columns;
    grid-gap: $gap;
    justify-content: $justify-content;
    align-items: $align-items;
}

@mixin grid-auto-rows($rows: 1fr, $gap: 0, $justify-content: center, $align-items: center) {
    display: grid;
    grid-auto-rows: $rows;
    grid-gap: $gap;
    justify-content: $justify-content;
    align-items: $align-items;
}

@mixin grid-auto-flow($flow: row, $gap: 0, $justify-content: center, $align-items: center) {
    display: grid;
    grid-auto-flow: $flow;
    grid-gap: $gap;
    justify-content: $justify-content;
    align-items: $align-items;
}

//media queries

/**
    * Breakpoints
    * mobile : 600px
    * tablet : 900px
    * small-desktop : 1280px
    * desktop : 1440px
    * medium-desktop : 1536px
    * large-desktop : 1920px
**/

@mixin mobile {
    @media (max-width: 600px) {
        @content;
    }
}
// small mobile
@mixin small-mobile{
    @media screen and (max-width: 300px) {
        @content;
    }
}


@mixin tablet {
    @media (min-width: 600px) and (max-width: 900px) {
        @content;
    }
}

@mixin small-desktop {
    @media (min-width: 900px) and (max-width: 1280px) {
        @content;
    }
}

@mixin desktop {
    @media (min-width: 1280px) and (max-width: 1440px) {
        @content;
    }
}


@mixin large-desktop {
    @media (min-width: 1440px){
        @content;
    }
}

@mixin extra-large-desktop {
    @media (min-width: 1920px) {
        @content;
    }
}











