.sidebar {
    display: grid;
    padding: 1.5875rem 1.4625rem 0rem 1.5625rem; /* Converted from 2.7rem 2.5rem 0px 2.5rem */
    // flex-direction: column;
    // justify-content: flex-end;
    // align-items: flex-start;
    // place-content: center;
    gap: 2.375rem; /* Converted from 3.8rem */
    // max-height: 90rem;
}
