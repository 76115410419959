@use '../../../styles/mixin' as *;

.cart__bottom {
    padding-block-start: 1.8125rem;
    padding-block-end: 1.4375rem;
    display: flex;
    justify-content: space-between;
    @include mobile{
        padding-block-start: .5rem;
        padding-block-end: .5rem;
    }


    .heading {
        h3 {
            color: #3C4242;
            font-family: 'Causten' sans-serif;
            font-size: 1.5rem; /* 2.4rem */
            font-style: normal;
            font-weight: 600;
            line-height: normal;
            padding-block-end: 0.625rem; /* 1rem */
            @include mobile {
                font-size:  1rem;
            }
        }

        p {
            color: #807D7E;
            font-family: "Causten" sans-serif;
            font-size: 1rem; /* 1.6rem */
            font-style: normal;
            font-weight: 400;
            line-height: normal;
            margin-block-end: 2.5rem; /* 4rem */
        }
    }

    .white__button {
        display: flex;
        // width: 14.5rem;
        padding: 0.75rem 1.25rem; /* 1.2rem 2rem */
        justify-content: center;
        align-items: center;
        gap: 0.75rem; /* 1.2rem */
        color: #3C4242;
        text-align: center;
        font-family: "Causten" sans-serif;
        font-size: 1rem; /* 1.6rem */
        font-style: normal;
        font-weight: 700;
        line-height: normal;
        border-radius: 0.5rem; /* 0.8rem */
        border: 0.0625rem solid #BEBCBD; /* 1px solid #BEBCBD */
        background: #FFF;
        cursor: pointer;
        transition: all 0.3s ease;

        &:hover {
            background: rgba(255, 255, 255, 0.5); /* rgba($color: #fff, $alpha: 0.5) */
        }
    }
}
