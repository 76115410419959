@use '../../styles/mixin' as *;
.data{
    padding-top: 0.75rem;
    // padding-inline-start: 2.5rem;
    // padding-inline-end: 2.5rem;
    padding-block-end: 2.5rem;

   &__section {
    padding-inline-start: 2.5rem;
    padding-inline-end: 2.5rem;
   display : grid;
   @include mobile{
    padding-inline-start: 1rem;
    padding-inline-end: 1rem;

   }
   @include tablet {
    padding-inline-start: 1.5rem;
    padding-inline-end: 1.5rem;

   }
//    place-content: center;
//    place-items: center;
   padding-block-start: 1.125rem;
   width: 100%;
   margin: 0 auto;
   gap: 2.5rem;


   .progress__tracker{
    margin-block-end: 3.125rem;
    @include mobile{
      display: none;
    }
    @include tablet{
      display: none;
    }


   }


   }
}
