// Variables

$line-height-base: 1.5;
$font-size-base: 1rem;

// Box sizing
html {
  box-sizing: border-box;
}

*, *::before, *::after {
  box-sizing: inherit;
}

// Reset margins and padding
body, h1, h2, h3, h4, h5, h6, p, blockquote, figure, img, dl, dd {
  margin: 0;
  padding: 0;
  margin-block-end: 0;
  margin-block-start: 0;
  margin-inline-end: 0;
  margin-inline-start: 0;
}

// Reset lists
ul, ol {
  margin: 0;
  padding: 0;
  list-style: none;
}

// Set default font family and size
body {
  font-size: $font-size-base;
  line-height: $line-height-base;
}

// Set default font size for headings
h1, h2, h3, h4, h5, h6 {
  font-size: inherit;
  font-weight: inherit;
}

// Set default vertical alignment for images
img {
  vertical-align: middle;
}

// Disable text highlighting
::-moz-selection {
  background-color: #b3d4fc;
  text-shadow: none;
}

::selection {
  background-color: #b3d4fc;
  text-shadow: none;
}

// Remove default border on active links
a {
  &::-moz-focus-inner {
    border: 0;
    padding: 0;
  }

  &:focus {
    outline: none;
  }
}

// Remove default border on images within links
a img {
  border: none;
}

// Disable horizontal scrollbar in IE and Edge
@supports (-ms-ime-align: auto) {
  html {
    overflow-x: hidden;
  }
}
