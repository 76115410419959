.avartar {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 2rem; // Converted from 4rem
    height: 2rem; // Converted from 4rem
    background-color: var(--gray-bg);
    border-radius: 50%; // Converted from 50%
    padding: 0.375rem; // Converted from 0.6rem

    img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        max-width: 1.75rem; // Converted from 2.8rem
        max-height: 1.75rem; // Converted from 2.8rem
    }
}