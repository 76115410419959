

.small__mobile{
    @media screen and (max-width: 300px ){
        padding: .65rem;
        padding-block-start: 3rem;

    }
}

img{
    cursor: pointer;
}