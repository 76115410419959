@use '../../styles/mixin' as *;

.servicescontainer {
    display: grid;
    gap: 2rem; /* Converted from 3.2rem */
    justify-content: center;
    padding-inline-start: 4.375rem; /* Converted from 7rem */
    padding-block-start: 3.75rem; /* Converted from 6rem */
    padding-inline-end: 4.375rem; /* Converted from 7rem */
    padding-block-end: 1.875rem; /* Converted from 3rem */

    @include mobile {
        padding-inline-start: 1.25rem;
        padding-inline-end: 1.25rem;
    }

    @include tablet {
        padding-inline-start: 2.25rem; /* Converted from 3rem */
        padding-inline-end: 2.25rem; /* Converted from 3rem */
    }
}
