@use '../../../styles/mixin' as *;

.menu {
    display: grid;
    gap: 7.625rem; /* Converted from 12.2rem */

    @include tablet {
        gap: 3.75rem; /* Converted from 6rem */
    }
    @include mobile {
        gap: 0.625rem; /* Converted from 1rem */
    }
}
