@use '../../../styles/mixin' as *;

.input_container {
    display: flex;
    flex-direction: column;
    margin-bottom: 1.25rem; /* 1.25rem */

    .label {
        color: var(--dark-text);
        font-weight: 500;
        font-size: 1rem; /* 1rem */
        margin-bottom: 0.5rem; /* 0.5rem */
        // @include tablet{
        //     text-align: center;
        // }
        // @include mobile{
        //     text-align: center;
        // }
    }

    .input {
        padding: 0.9375rem 1.5625rem 1rem 1.25rem; /* 0.9375rem 1.5625rem 1rem 1.25rem */
        border: 0.0625rem solid var(--gray-text); /* 0.0625rem */
        border-radius: 0.625rem; /* 0.625rem */
        font-size: 0.875rem; /* 0.875rem */
        color: var(--dark-text);
        background-color: var(--gray-light);
        width: 100%;

        &::placeholder {
            color: #9e9e9e;
            font-weight: 400;
            font-size: 0.875rem; /* 0.875rem */
        }
    }
}
