@use '../../styles/mixin' as *;

.pagination {
    display: flex;
    align-items: center;
    gap: .625rem;
    flex-wrap: wrap;

    &__list {
        display: flex;
        align-items: center;
        gap: 1rem;
        list-style: none;
        flex-wrap: wrap;

        li {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            padding: 0.625rem 0.9375rem; // Converted from 1rem 1.5rem
            gap: 0.625rem; // Converted from 10px
            color: #C1BCBC;
            width: 2.25rem; // Converted from 3.6rem
            height: 2.3125rem; // Converted from 3.7rem
            background: #FFF;
            transition: all 0.3s ease-in-out;

            &.pagination__item__active {
                background: var(--primary);
                color: #fff;
            }

            &:hover {
                background: var(--primary);
                color: #fff;
                cursor: pointer;
            }
        }
    }

    &__button {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        padding: 0.625rem 0.9375rem; // Converted from 1rem 1.5rem
        gap: 0.625rem; // Converted from 1rem
        background: #FFF;
        font-size: 0.875rem; // Converted from 1.4rem
        color: #C1BCBC;
        border-radius: 0.3125rem; // Converted from 0.5rem
        &:hover {
            background: var(--primary);
            color: #fff;
            cursor: pointer;
        }
    }
}

.page_link, .previous, .next {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 0.625rem 0.9375rem; // Converted from 1rem 1.5rem
    gap: 0.625rem; // Converted from 1rem
    background: #FFF;
    font-size: 0.875rem; // Converted from 1.4rem
    color: #C1BCBC;
    border-radius: 0.3125rem; // Converted from 0.5rem
    &:hover {
        background: var(--primary);
        color: #fff;
        cursor: pointer;
    }
}

.active {
    .page_link {
        background: var(--primary);
        color: #fff;
        cursor: pointer;
    }
}

.disabled {
    .previous, .next {
        background: #EAEAEA;
        color: #9f9f9fc9;
        cursor: not-allowed;
    }
}
