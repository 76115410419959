@use '../../styles/mixin' as *;
.data{
    padding-top: 12px;
    // padding-inline-start: 4px;
    // padding-inline-end: 4px;
    padding-block-end: 40px;

   &__section {
    padding-inline-start: 40px;
    padding-inline-end: 40px;
   display : grid;
//    place-content: center;
//    place-items: center;
   padding-block-start: 18px;
   width: 100%;
   margin: 0 auto;
   gap: 40px;


   .progress__tracker{
    margin-block-end: 50px;
    @include mobile{
      display: none;
    }
    @include tablet{
      display: none;
    }


   }


   }
}